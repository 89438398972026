import request from './request'
import { InsuranceOpenDto } from '@/api/insurance'

// 加减保详情
export interface OrderDto {
  order_id: number
  type: string
  start_date: string
  policy: InsuranceOpenDto
  person: {
    employee: {
      name: string
      idcard: string
    }
    occupation_name: string
    occupation_level: string
    company_name: string
  }[]

}

export function getOrderDetail (params: {
  orderId: string
}): Promise<OrderDto> {
    return request.get('/order/detail', {
        params
    })
}
