
import { getOrderDetail, OrderDto } from '@/api/order'
import { defineComponent } from 'vue'
import PageHead from '@/views/index/components/page-head.vue'

export default defineComponent({
    name: 'InsuranceOrder',
    components: {
        PageHead
    },
    data () {
        return {
            data: {} as OrderDto
        }
    },

    async created () {
        const result = await getOrderDetail({
            orderId: this.$route.params.id as string
        })
        this.data = result
    }
})
